import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postMessage } from "../services/message-service";
import { IMailFormState } from "../state/MailFormState";

const InitialState: IMailFormState = {
    Subject: "",
    Message: "",
    IsLoading: false
}


export const postEmail = createAsyncThunk(
    "mailform/postEmail",
    async ({ accessToken, subject, message }: any) => {
        return await postMessage(accessToken, subject, message)
    });

const mailFormSlice = createSlice({
    name: "mailform",
    initialState: InitialState,
    reducers: {
        updateSubject: (state, action) => {
            state.Subject = action.payload;
        },
        updateMessage: (state, action) => {
            state.Message = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(postEmail.pending, (state, action) => {
            state.IsLoading = true;
        });
        builder.addCase(postEmail.fulfilled, (state, action) => {
            state.IsLoading = false;
            state.SendStatus = action.payload;
        });
        builder.addCase(postEmail.rejected, (state, action) => {
            state.IsLoading = false;
        });
    }
});

export default mailFormSlice.reducer;
export const mailFormActions = mailFormSlice.actions;