import { ContactSupport, Dashboard, Home, LibraryBooks, LocalLibrary, Mail, Man, School, Settings } from "@mui/icons-material";
import { Avatar, Box, Divider, Stack, Typography } from "@mui/material";


export const PageHeader = (props: PageHeaderProps) => {
    const getIcon = (icon: string) => {
        switch (icon) {
            case "Settings":
                return <Settings />;
            case "Profile":
                return <Man />;
            case "Dashboard":
                return <Dashboard />;
            case "Mail":
                return <Mail />;
            case "Contact":
                return <ContactSupport />;
            case "Terms":
                return <LibraryBooks />;
            case "School":
                return <School />;
            case "LocalLibrary":
                return <LocalLibrary />;
            default:
                return <Home />;
        }
    }
    return (
        <Box>
            <Stack direction="row" spacing={2} >
                <Avatar
                    sx={{ width: 64, height: 64, bgcolor: 'primary.main' }}
                    variant="rounded" >
                    {getIcon(props.icon)}
                </Avatar>
                <Stack direction="column" >
                    <Typography variant="h5" gutterBottom>{props.title}</Typography>
                    <Typography variant="body2" gutterBottom>{props.titleDesc}</Typography>
                </Stack>
            </Stack>
            <Divider variant='fullWidth' sx={{ paddingTop: 1, paddingBottom: 1 }} />
        </Box>
    );
}

interface PageHeaderProps {
    title: string;
    titleDesc?: string;
    icon: "Settings" | "Profile" | "Dashboard" | "Home" | "Mail" | "Contact" | "Terms" | "NotFound" | "School" | "LocalLibrary";
}