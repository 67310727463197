import React from 'react';
import { IRootState } from '../../state/rootState';
import { Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { Avatar, Button, Container, Divider, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Snackbar, Stack, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { PageLayout } from '../../components/PageLayout';
import { fetchPasswordReset, profileActions } from '../../redux/ProfileReducer';
import rootStore from '../../state/rootStore';
import { Key } from '@mui/icons-material';


interface ProfileProps {
    state: IRootState;
}

interface ProfileDispatch {
    clearMessage: () => void;
}

type Props = ProfileProps & ProfileDispatch;

// main component render
export const ProfilePage: React.FC<Props> = (props) => {
    // if auth0 is not used, use user properties from redux store
    const { isAuthenticated, user } = useAuth0();
    const dispatch = useDispatch<typeof rootStore.dispatch>();

    const sendPasswordReset = () => {
        dispatch(fetchPasswordReset({ email: user.email }));
    }

    return (<PageLayout>
        {props.state.profilePage.Message && <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={true}
            onClose={props.clearMessage}
            autoHideDuration={3000}
            message={props.state.profilePage.Message}
        />}
        {isAuthenticated &&
            <Container maxWidth="lg" sx={{ paddingTop: 3 }} >
                <Stack direction="row" spacing={2} >
                    <Avatar
                        alt={user.name}
                        src={user.picture}
                        sx={{ width: 64, height: 64, bgcolor: 'primary.main' }}
                        variant="rounded" />
                    <Stack direction="column" >
                        <Typography variant="h6" gutterBottom>{user.name}</Typography>
                        <Typography variant="body2" gutterBottom>{user.email}</Typography>
                    </Stack>
                </Stack>
                <Divider variant='fullWidth' sx={{ marginTop: 1, marginBottom: 1 }} />
                <Stack direction="row" spacing={2}>
                    <Stack direction="column" >
                        <Typography variant="body2" gutterBottom>Profile updated on :{user.updated_at}</Typography>
                    </Stack>
                </Stack>
                <List
                    sx={{ width: '100%' }}
                    subheader={<ListSubheader sx={{ backgroundColor: "background.default" }}>Security</ListSubheader>}
                >
                    <ListItem>
                        <ListItemIcon>
                            <Key />
                        </ListItemIcon>
                        <ListItemText id="list-label-password-reset"
                            primary="Password"
                            secondary="Password reset email will send to your email address." /><br />
                        <Button variant="contained"
                            onClick={sendPasswordReset}
                            disabled={props.state.profilePage.IsPassResetDone}
                        >Reset Password</Button>
                    </ListItem>
                    <Divider />
                </List>
            </Container>
        }
    </PageLayout>
    )
}

const mapStateToProps = (state: IRootState): ProfileProps => ({
    state
});

const mapDispatchToProps = (dispatch: Dispatch): ProfileDispatch => ({
    clearMessage: () => dispatch(profileActions.clearMessage())
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);