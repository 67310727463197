import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Apps, ChevronLeft, Download, Email, Home, LibraryBooks, LockPerson, School } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { IRootState } from '../../../state/rootState';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IDashboardItem } from '../../../state/dashboardState';
import { useAuth0 } from '@auth0/auth0-react';
import { dashboardActions } from '../../../redux/DashboardReducer';


interface MenuBarProps {
    state: IRootState;
}

interface MenuBarDispatch {
    setDashboardActive: (payload) => void;
}

interface MuiProps {
    openState: boolean;
    toggleFunction: any;
}

type Props = MenuBarProps & MenuBarDispatch & MuiProps;


export const MainMenu: React.FC<Props> = (props) => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const navigate = useNavigate();
    const toggleMenu = () => {
        props.toggleFunction(false);
    }
    
    const navMenu = (param) => {
        switch (param) {
            case "dashboard": {
                navigate("/dashboard");
                break;
            }
            case "profile": {
                navigate("/profile");
                break;
            }
            case "settings": {
                navigate("/settings");
                break;
            }
            case "termsofuse": {
                navigate("/termsofuse");
                break;
            }
            case "contactiress": {
                navigate("/contactform");
                break;
            }
            case "tutorial": {
                navigate("/tutorial");
                break;
            }
            case "documentation": {
                navigate("/documentation");
                break;
            }
            default:
                navigate("/");
                break;
        }

    }

    const navigateLogin = async () => {
        await loginWithRedirect({
            // redirect url after login process
            appState: {
                returnTo: "/callback",
            },
            authorizationParams: {
                prompt: "login"
            },
        });
    }

    const dashboardItemMenuClick = (dashboard: IDashboardItem) => {
        props.setDashboardActive(dashboard);
        navigate("/dashboard/detail");
    }

    const renderDashboardItems = () => {
        if (props.state.dashboard) {
            if (props.state.dashboard.DashboardSummaryList) {
                if (props.state.dashboard.DashboardSummaryList.length > 0) {
                    return props.state.dashboard.DashboardSummaryList.map((dashboard: IDashboardItem, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButton onClick={(e) => dashboardItemMenuClick(dashboard)}>
                                <ListItemIcon sx={{ minWidth: 40 }}>

                                </ListItemIcon>
                                <ListItemText primary={dashboard.Name} sx={{ marginLeft: 0 }} />
                            </ListItemButton>
                        </ListItem>
                    ))
                }
            }
        }
        return <></>
    }

    const renderDashboardList = () => {

        if (isAuthenticated) {
            return (
                <List>
                    <ListItem key="Home" disablePadding >
                        <ListItemButton onClick={(e) => navMenu("")}>
                            <ListItemIcon sx={{ minWidth: 40 }}>
                                <Home />
                            </ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem key="DashboardList" disablePadding >
                        <ListItemButton onClick={(e) => navMenu("dashboard")}>
                            <ListItemIcon sx={{ minWidth: 40 }}>
                                <Apps />
                            </ListItemIcon>
                            <ListItemText primary="Dashboards" />
                        </ListItemButton>
                    </ListItem>
                    {renderDashboardItems()}
                </List>
            )
        } else {
            return (
                <List>
                    <ListItem key="No Dashboards" disablePadding>
                        <ListItemButton onClick={navigateLogin}>
                            <ListItemIcon sx={{ minWidth: 40 }}>
                                <LockPerson />
                            </ListItemIcon>
                            <ListItemText primary="Login" />
                        </ListItemButton>
                    </ListItem>
                </List>
            )
        }
    }

    const DrawerList = (
        <Box sx={{ width: { xs: "80vw", md: "20vw" }, minWidth: 200 }} role="presentation" onClick={toggleMenu}>
            <List sx={{ bgcolor: "primary.main", color: "white" }}>
                <ListItemButton>
                    <ListItemText
                        sx={{ my: 0 }}
                        primary=""
                        primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: 'medium',
                            letterSpacing: 0,
                        }}
                    />
                    <ChevronLeft />
                </ListItemButton>
            </List>
            <Divider />
            {renderDashboardList()}
            <Divider />
            <List>
                {isAuthenticated &&
                    <ListItem key="contactiress" disablePadding>
                        <ListItemButton onClick={(e) => navMenu("contactiress")}>
                            <ListItemIcon sx={{ minWidth: 40 }}>
                                <Email />
                            </ListItemIcon>
                            <ListItemText primary={"Contact Us"} />
                        </ListItemButton>
                    </ListItem>
                }
                <ListItem key="termsofuse" disablePadding>
                    <ListItemButton onClick={(e) => navMenu("termsofuse")}>
                        <ListItemIcon sx={{ minWidth: 40 }}>
                            <LibraryBooks />
                        </ListItemIcon>
                        <ListItemText primary={"Terms of Use"} />
                    </ListItemButton>
                </ListItem>
                {isAuthenticated && ['Tutorials'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton onClick={(e) => navMenu("tutorial")}>
                            <ListItemIcon sx={{ minWidth: 40 }}>
                                <School />
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}

                {isAuthenticated &&
                    <ListItem key="doc" disablePadding>
                        <ListItemButton href="assets/doc/User_Guide.pdf" download="User_Guide" target='_fundflowuserguide'>
                            <ListItemIcon sx={{ minWidth: 40 }}>
                                <Download />
                            </ListItemIcon>
                            <ListItemText primary={"Download User Guide"} />
                        </ListItemButton>
                    </ListItem>
                }
            </List>
        </Box>
    );

    return (
        <Drawer
            open={props.openState}
            onClose={toggleMenu}
            anchor='left'
        >
            {DrawerList}
        </Drawer>
    );
}


const mapStateToProps = (state: IRootState): MenuBarProps => ({
    state
});

const mapDispatchToProps = (dispatch: Dispatch): MenuBarDispatch => ({
    setDashboardActive: (payload) => dispatch(dashboardActions.setDashboardActive(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
