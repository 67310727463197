import React, { useEffect } from 'react';
import { IRootState } from '../../state/rootState';
import { Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { dashboardActions, fetchDashboardList } from '../../redux/DashboardReducer';
import { Alert, Container, Grid } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { PageLayout } from '../../components/PageLayout';
import DashboardList from '../../components/dashboard/DashboardMenu';
import { IDashboardItem } from '../../state/dashboardState';
import { useNavigate } from 'react-router-dom';
import { GenericLoader } from '../../components/GenericLoader';
import rootStore from '../../state/rootStore';


interface DashboardListProps {
    state: IRootState;
}

interface DashboardListDispatch {
    setDashboardActive: (payload) => void;
}

type Props = DashboardListProps & DashboardListDispatch;

// main component render
export const Dashboard: React.FC<Props> = (props) => {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const dispatch = useDispatch<typeof rootStore.dispatch>();

    useEffect(() => {
        if (props.state.dashboard.DashboardListLoaded === false) {
            const getDashboardApi = async () => {
                const accessToken = await getAccessTokenSilently();
                await dispatch(fetchDashboardList({ accessToken }));
            }
            getDashboardApi();
        }
    }, [props, dispatch, getAccessTokenSilently]);

    const handleButtonClick = async (dashboard: IDashboardItem) => {
        // set active dashboard navigate to detail page
        props.setDashboardActive(dashboard);
        navigate("/dashboard/detail")
    }

    return (
        <PageLayout>
            {props.state.dashboard.IsLoading ? <GenericLoader />
                :
                <Container maxWidth="lg" sx={{ paddingTop: 3 }}>
                    <Grid container spacing={4}  >
                        <DashboardList dashboards={props.state.dashboard.DashboardSummaryList} callBack={handleButtonClick} />
                    </Grid>
                    {props.state.dashboard.DashboardSummaryList.length === 0 && 
                    <Alert severity='info' variant='outlined' sx={{ marginTop: 3 }} >No Dashboard Found.</Alert>
                    }
                </Container>
            }
        </PageLayout >
    )
}

const mapStateToProps = (state: IRootState): DashboardListProps => ({
    state
});

const mapDispatchToProps = (dispatch: Dispatch): DashboardListDispatch => ({
    setDashboardActive: (payload) => dispatch(dashboardActions.setDashboardActive(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
