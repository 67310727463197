import { useAuth0 } from "@auth0/auth0-react";
import { Outlet } from "react-router-dom";
import { HomePage } from "../section/HomePage";


const ProtectedRoutes: React.FC<{}> = () => {
    const { isAuthenticated } = useAuth0();

    return(
        isAuthenticated? <Outlet />:<HomePage IsSessionTimeOut={true} />
    )
}

export default ProtectedRoutes;