import { createSlice } from "@reduxjs/toolkit";
import { IMessageState } from "../state/rootState";


const InitState: IMessageState[] = [];

const toasterSlice = createSlice({
    name: "toaster",
    initialState: InitState,
    reducers: {
        addToast: (state, action) => {
            state.push(action.payload);
        },
        delToast: (state, action) => {
            state.splice(action.payload, 1);
        },
        clearToasts: (state) => {
            state = [];
        }
    }
});

// export reducer
export default toasterSlice.reducer; // userReducer;
export const toasterActions = toasterSlice.actions; // userActions
