import { Grid } from "@mui/material";
import MenuCard from "./MenuCard";
import { IDashboardItem } from "../../state/dashboardState";
import chart_00 from "../../assets/images/chart_00.png";
import chart_01 from "../../assets/images/chart_01.png";
import chart_02 from "../../assets/images/chart_02.png";
import chart_03 from "../../assets/images/chart_03.png";
import chart_04 from "../../assets/images/chart_04.png";
import chart_05 from "../../assets/images/chart_05.png";
import chart_06 from "../../assets/images/chart_06.png";


type DashboardListProps = {
    dashboards: IDashboardItem[],
    callBack: any
}

const DashboardList = ({ dashboards, callBack }: DashboardListProps) => {

    const getChartImage = (index: number) => {
        switch (index % 7) {
            case 0:
                return chart_00;
            case 1:
                return chart_01;
            case 2:
                return chart_02;
            case 3:
                return chart_03;
            case 4:
                return chart_04;
            case 5:
                return chart_05;
            case 6:
                return chart_06;
            default:
                return chart_00
        }
    }

    return (<>
        {dashboards.map((dashboard: IDashboardItem, index: number) => {
            return (
                <Grid item xs={12} md={3} key={dashboard.DashboardId}>
                    <MenuCard
                        dashboardId={dashboard.DashboardId}
                        dashboard={dashboard}
                        image={getChartImage(index)}
                        title={dashboard.Name}
                        description=""
                        callBackFunction={callBack}
                    />
                </Grid>
            )
        })
        }
    </>);
};

export default DashboardList;