import React from "react";
import { PageLayout } from "../components/PageLayout";
import { Alert, Box, Button, Checkbox, Container, FormControlLabel, Grid, LinearProgress, TextField, Typography } from "@mui/material";
import { PageHeader } from "../components/PageTitle";
import { connect, useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { mailFormActions, postEmail } from "../redux/MailFormReducer";
import { useAuth0 } from "@auth0/auth0-react";
import rootStore from "../state/rootStore";
import { toasterActions } from "../redux/NotiReducer";
import { IMailFormState } from "../state/MailFormState";
import ImgContact from "../assets/images/contact.png";

interface DashboardListProps {
    state: IMailFormState;
}

interface DashboardListDispatch {
    updateSubject: (subject: string) => void;
    updateMessage: (message: string) => void;
    setNoti: (payload: any) => void;
}

type Props = DashboardListProps & DashboardListDispatch;

// main component render
const MailFormPage: React.FC<Props> = (props) => {
    const [term_agree, setAgree] = React.useState(false);
    const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
    const dispatch = useDispatch<typeof rootStore.dispatch>();

    const sendMail = async () => {
        const accessToken = await getAccessTokenSilently();
        const subject = props.state.Subject;
        const message = props.state.Message;
        if (term_agree === false) {
            props.setNoti({
                Type: "warning",
                Message: "Please agree to the terms of use."
            });
        } else {
            if (subject === "" || message === "")
                props.setNoti({
                    Type: "error",
                    Message: "Please provide subject and message details."
                });
            else {
                await dispatch(postEmail({ accessToken, subject, message }));
            }
        }
    }

    return (
        <PageLayout>
            <Container maxWidth="lg" sx={{ paddingTop: 3 }} component="main" >
                <PageHeader icon="Mail" title="Contact Us" titleDesc="Tell Us More, We Can Serve You Better" />
                {props.state.IsLoading && <LinearProgress />}
                {props.state.SendStatus?.error &&
                    <Alert severity="error" sx={{ margin: 1 }}>
                        Sending message fail.</Alert>
                }
                {props.state.SendStatus?.data &&
                    <Alert severity="info" sx={{ margin: 1 }}>
                        Message successfuly send. We will response as soon as possible.</Alert>
                }
                <Grid container >
                    <Grid item xs={12} md={6} key="mailform-info">
                        <Box sx={{ padding: 1 }}>
                            <img src={ImgContact} alt="contact" width="100%" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} key="mailform-body">
                        <Box component="form"
                            noValidate
                            autoComplete="off"
                            sx={{ padding: 1 }}>
                            <TextField
                                required
                                disabled
                                id="mail-from"
                                label="From"
                                defaultValue=""
                                variant="outlined"
                                value={user.email}
                                fullWidth
                                sx={{ marginBottom: 1, marginTop: 1 }}
                            />
                            <TextField
                                required
                                error={props.state.Subject === ""}
                                id="mail-subject"
                                label="Subject"
                                defaultValue=""
                                variant="outlined"
                                value={props.state.Subject}
                                onChange={(e) => props.updateSubject(e.target.value)}
                                fullWidth
                                sx={{ marginBottom: 1, marginTop: 1 }}
                            />
                            <TextField
                                required
                                error={props.state.Message === ""}
                                id="mail-message"
                                label="Message"
                                multiline
                                rows={10}
                                value={props.state.Message}
                                defaultValue=""
                                variant="outlined"
                                onChange={(e) => props.updateMessage(e.target.value)}
                                fullWidth
                                sx={{ marginBottom: 1, marginTop: 1 }}
                            />
                        </Box >
                        <Box>
                            <FormControlLabel
                                control={<Checkbox id="mail-terms" sx={{ verticalAlign: "top" }} />}
                                label="I understand that by submitting this form, I consent to receive communications from IRESS Ltd by mails or emails."
                                sx={{ m: 1 }}
                                checked={term_agree}
                                onClick={(e) => setAgree(!term_agree)}
                            />

                        </Box>
                        <Box sx={{ padding: 1 }}>
                            <Typography variant="subtitle1">
                                We will process any personal information collected on this page in accordance with <a target="_privacy" href="https://www.iress.com/resources/legal/privacy/">our privacy policy</a>.
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="flex-end" padding={1} >
                            {isAuthenticated &&
                                <Button variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={props.state.IsLoading || props.state.SendStatus?.data ? true : false}
                                    onClick={sendMail}
                                    sx={{ marginLeft: "auto" }} >Submit</Button>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Container >
        </PageLayout >
    );
}

const mapStateToProps = (state: IMailFormState): DashboardListProps => ({
    state: rootStore.getState().mailform
});

const mapDispatchToProps = (dispatch: Dispatch): DashboardListDispatch => ({
    updateMessage: (message: string) => dispatch(mailFormActions.updateMessage(message)),
    updateSubject: (subject: string) => dispatch(mailFormActions.updateSubject(subject)),
    setNoti: (payload) => dispatch(toasterActions.addToast(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MailFormPage);
