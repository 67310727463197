import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import App from "./app/app";
import { Auth0ProviderWithHistory } from "./services/auth0-provider-with-history";
import { Provider } from "react-redux";
import rootStore from "./state/rootStore";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

// wrap with React.StrictMode if want to enable strict mode
root.render(
    <HashRouter>
        <Auth0ProviderWithHistory>
            <Provider store={rootStore} >
                <App />
            </Provider>
        </Auth0ProviderWithHistory>
    </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// Use HashRoputer instead of BrowserRouter to compatible with S3 static hosting