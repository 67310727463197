import { createSlice } from "@reduxjs/toolkit";
import { IUserState } from "../state/userState";


const InitState: IUserState = {
};

const userSlice = createSlice({
    name: "user",
    initialState: InitState,
    reducers: {
        setUser: (state, action) => {
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.gender = action.payload.gender;
            state.locale = action.payload.locale;
            state.phone_number_verified = action.payload.phone_number_verified;
            state.picture = action.payload.picture;
            state.updated_at = action.payload.updated_at;
        }
    }
});

// export reducer
export default userSlice.reducer; // userReducer;
export const userActions = userSlice.actions; // userActions
