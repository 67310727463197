import { Button, Card, CardMedia, CardContent, CardActions, Typography, Box, IconButton } from '@mui/material';
import { IDashboardItem } from '../../state/dashboardState';
import chart_01 from "../../assets/images/chart_01.png";
import { Favorite } from '@mui/icons-material';

const MenuCard = (props: MenuCardProps) => {

    return (
        <Card variant="elevation" elevation={2} >
            <Box sx={{ position: 'relative' }}>
                <CardMedia
                    image={props.image ? props.image : chart_01}
                    title={props.title}
                    component="img"
                />
                <Box sx={{
                    position: { xs: 'absolute', md: 'relative' },
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    bgcolor: { xs: 'rgba(0, 0, 0, 0.40)', md: 'inherit' },
                    color: { xs: 'white', md: 'inherit' },
                }}>
                    <CardContent

                    >
                        <Typography gutterBottom variant="subtitle1" >
                            {props.title}
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: "space-between", padding: 1, 
                        bgcolor: { xs: 'rgba(255, 255, 255, 0.95)', md: 'inherit' },
                     }} >
                        <Box sx={{ alignContent: "flex-start" }}>
                            <IconButton aria-label="Favourate">
                                <Favorite />
                            </IconButton>
                        </Box>
                        <Box sx={{ alignContent: "flex-end" }}>
                            <Button
                                color="info"
                                variant="text"
                                size='medium'
                                onClick={() => { props.callBackFunction(props.dashboard) }}
                            >View Dashboard</Button>
                        </Box>
                    </CardActions>
                </Box>
            </Box>
        </Card>
    )
};

interface MenuCardProps {
    dashboardId: string;
    dashboard: IDashboardItem;
    title: string;
    description?: string;
    image?: string;
    callBackFunction?: any;
}

export default MenuCard;