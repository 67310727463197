
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTutorialList } from "../services/tutorial-service";
import { ITutorialPage } from "../state/tutorialState";

const InitialState: ITutorialPage = {
    TutorialList: [],
    TutorialListLoaded: false,
    IsLoading: false,
}

// this function will generate pending, fullfilled and rejected action types
export const fetchTutorialList = createAsyncThunk(
    "tutorial/getTutorialList",
    async ({ accessToken }: any) => {
        return await getTutorialList(accessToken)
            .then((response) =>
                response.data ? response.data.tutorial_list : [])
    });

export const fetchTutorialListPublic = createAsyncThunk(
    "tutorial/getTutorialListPublic",
    async () => {
        try {
            return fetch("assets/videos/tutorials.json"
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonObj) {
                    return jsonObj.tutorial_list;
                })
        } catch (error) {
            return [];
        }
    });

const tutorialSlice = createSlice({
    name: "tutorial",
    initialState: InitialState,
    reducers: {
        setTutorialLoaded: (state) => {
            state.TutorialListLoaded = true;
        },
        setActiveTutorial: (state, action) => {
            state.ActiveTutorial = action.payload;
        },
        clearActiveTutorial: (state) => {
            state.ActiveTutorial = null;
        }
    },
    extraReducers: builder => {
        // getTurotialList
        builder.addCase(fetchTutorialList.pending, (state) => {
            state.TutorialListLoaded = true;
            state.IsLoading = true;

        })
        builder.addCase(fetchTutorialList.fulfilled, (state, action) => {
            state.IsLoading = false;
            state.TutorialList = action.payload;

        })
        builder.addCase(fetchTutorialList.rejected, (state, action) => {
            state.TutorialListLoaded = true;
            state.IsLoading = false;

        })
        // getTurotialListPublic
        builder.addCase(fetchTutorialListPublic.pending, (state) => {
            state.TutorialListLoaded = true;
            state.IsLoading = true;

        })
        builder.addCase(fetchTutorialListPublic.fulfilled, (state, action) => {
            state.IsLoading = false;
            state.TutorialList = action.payload;

        })
        builder.addCase(fetchTutorialListPublic.rejected, (state, action) => {
            state.TutorialListLoaded = true;
            state.IsLoading = false;

        })
    },
});

export default tutorialSlice.reducer;
export const tutorialActions = tutorialSlice.actions;