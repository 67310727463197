
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IDashboardPage } from "../state/dashboardState";
import { getDashboardList, getDashboardUrl } from "../services/dashboard-request-service";

const InitialState: IDashboardPage = {
    DashboardSummaryList: [],
    DashboardListLoaded: false,
    DashboardLoaded: false,
    IsLoading: false,
}

// this function will generate pending, fullfilled and rejected action types
export const fetchDashboardList = createAsyncThunk(
    "dashboard/getDashboardList",
    async ({ accessToken }: any) => {
        return await getDashboardList(accessToken)
            .then((response) =>
                response.data ? response.data.DashboardSummaryList : [])
    });


export const fetchDashboardUrl = createAsyncThunk(
    "dashboard/getDashboardUrl",
    // AsyncThunk received only one param for custom params. So use JSON object to pass multiple params
    async ({ accessToken, dashboardId }: any) => {
        return await getDashboardUrl(accessToken, dashboardId)
            .then((response) =>
                response.data ? response.data : {})
    });

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: InitialState,
    reducers: {
        setDashboardLoaded: (state) => {
            state.DashboardLoaded = true;
        },
        setDashboardActive: (state, action) => {
            state.ActiveDashboard = action.payload;
            state.DashboardLoaded = false;
        },
        setDashboardActiveCalled: (state) => {
            state.DashboardLoaded = true;
        },
        setLoadingStatus: (state, action) => {
            state.IsLoading = action.payload;
        }

    },
    extraReducers: builder => {
        // getDashboardList
        builder.addCase(fetchDashboardList.pending, (state) => {
            state.DashboardListLoaded = true;
            state.IsLoading = true;
        })
        builder.addCase(fetchDashboardList.fulfilled, (state, action) => {
            state.DashboardListLoaded = true;
            state.DashboardSummaryList = action.payload;
            state.IsLoading = false;
        })
        builder.addCase(fetchDashboardList.rejected, (state, action) => {
            state.DashboardListLoaded = true;
            state.DashboardSummaryList = [];
            state.IsLoading = false;
        })
        // getDashboardUrl
        builder.addCase(fetchDashboardUrl.pending, (state) => {
            state.DashboardLoaded = true;
            state.IsLoading = true;
        })
        builder.addCase(fetchDashboardUrl.fulfilled, (state, action) => {
            state.DashboardLoaded = true;
            state.ActiveUrl = action.payload;
            state.IsLoading = false;
        })
        builder.addCase(fetchDashboardUrl.rejected, (state, action) => {
            state.DashboardLoaded = true;
            state.ActiveUrl = undefined;
            state.IsLoading = false;
        })
    },
});

export default dashboardSlice.reducer;
export const dashboardActions = dashboardSlice.actions;