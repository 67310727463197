import React from "react";
import { PageLayout } from "../components/PageLayout";
import { Alert, AlertTitle, Button, Card, CardMedia, Container, Grid, Typography } from "@mui/material";
import img_01 from "../assets/images/home_01.png";
import { useAuth0 } from "@auth0/auth0-react";

export const HomePage: React.FC<HomePageProps> = (props) => {
    const { loginWithRedirect } = useAuth0();

    return (
        <PageLayout>
            {props.IsSessionTimeOut &&
                <Container maxWidth="lg" sx={{ paddingTop: 3 }} >
                    <Alert severity="error" variant="outlined" sx={{ marginBottom: 3 }}
                        action={
                            <Button variant="contained" data-testid="login"
                            onClick={()=>{
                                loginWithRedirect({
                                    appState: {
                                        returnTo: "/callback",
                                    },
                                    authorizationParams: {
                                        prompt: "login",
                                    },
                                });
                            }}>Login</Button>
                        }>
                        <AlertTitle>Session Timeout</AlertTitle>
                        Your session has expired. Please login again.
                    </Alert>
                </Container>
            }
            <Container maxWidth="lg" sx={{ paddingTop: 3 }} >
                <Grid container spacing={3} alignItems="center" justifyContent="center" >
                    <Grid item xs={12} md={6} alignItems={"center"} >
                        <Card elevation={0} sx={{ padding: 0 }} >
                            <CardMedia
                                title="Financial services software built for better performance"
                                image={img_01}
                                component={"img"}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <Typography variant="h4" align="left" sx={{ marginBottom: 3 }} >
                            Financial services software built for better performance
                        </Typography>
                        <Typography variant="body1" align="justify" sx={{ marginBottom: 3 }} >
                            It's not always easy running a financial services business. Every day there are more demands on time and money. More data. More information. More compliance. More reporting. More competition.
                        </Typography>
                        <Typography variant="body1" align="justify" sx={{ marginBottom: 3 }} >
                            We can help. Around the world, thousands of financial advisers, wealth managers, investment managers, traders, lenders and mortgage brokers rely on our software to run smarter businesses and deliver better to the millions of people who rely on them.
                        </Typography>
                        <Typography variant="body1" align="justify" sx={{ marginBottom: 3 }} >
                            To find out how we can help your financial services business, get in touch.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6} >
                        <Typography variant="h4" align="left" sx={{ marginBottom: 3 }} >
                            To trade smarter in today’s market, you need the right information, tools and support.
                        </Typography>
                        <Typography variant="body1" align="justify" sx={{ marginBottom: 3 }} >
                            It is our mission to empower our clients with better ways to connect, trade and grow. We deeply understand our clients' businesses, how fast they move and the challenges they have.
                        </Typography>
                        <Typography variant="body1" align="justify" sx={{ marginBottom: 3 }} >
                            More than 12,000+ institutional, retail and online traders around the world use Iress' trading and market data software to connect across multiple markets, monitor and manage risk, access end-to-end multi-asset trading support and deliver better performance.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <Card elevation={0} sx={{ padding: 1 }} >
                            <CardMedia
                                title="Financial services software built for better performance"
                                image="https://www.iress.com/media/images/xplan-graphic.2e16d0ba.fill-600x650-c100.png"
                                component={"img"}
                                height={300}
                            />
                        </Card>

                    </Grid>

                </Grid>
            </Container>
        </PageLayout>
    );
}

interface HomePageProps {
    IsSessionTimeOut: boolean;
}
