import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Avatar, Box, Divider, IconButton, Tooltip } from "@mui/material";
import { NavButton } from '../buttons/btn-nav';
import { useAuth0, User } from '@auth0/auth0-react';


const UserAccountMenu = (props: userProps) => {
    const { user } = useAuth0();
    // const [auth0User, setAuth0User] = React.useState<User>();
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    // setAuth0User(user);

    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Account settings">
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleOpenUserMenu} >
                    <Avatar alt={user?.name} src={user?.picture} variant="rounded" />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {props.user.name && <NavButton
                    label="Account detail"
                    is_menu={true}
                    route="/profile"
                    onClose={handleCloseUserMenu}
                />}
                <NavButton
                    label="Preferences"
                    is_menu={true}
                    route="/settings"
                    onClose={handleCloseUserMenu}
                />
                <Divider />
                <NavButton
                    label="Logout"
                    auth0_function="logout"
                    is_menu={true}
                    route="/dashboard"
                    onClose={handleCloseUserMenu}
                />
            </Menu>
        </Box>
    );
}

interface userProps {
    user: User;
}

export default UserAccountMenu;