import React from 'react';
import { IRootState } from '../../state/rootState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Container, Divider, List, ListItem, ListItemIcon, ListItemText, ListSubheader, PaletteMode, Switch } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { PageLayout } from '../../components/PageLayout';
import { ColorLens } from '@mui/icons-material';
import { themeActions } from '../../redux/reducers';
import { PageHeader } from '../../components/PageTitle';


interface SettingProps {
    state: IRootState;
}

interface SettingDispatch {
    setThemeMode: (mode: PaletteMode) => void;
}

type Props = SettingProps & SettingDispatch;

// main component render
export const SettingPage: React.FC<Props> = (props) => {
    const { isAuthenticated } = useAuth0();


    const toggleThemeMode = () => {
        if (props.state.theme.mode === "dark") {
            props.setThemeMode("light");
        } else {
            props.setThemeMode("dark");
        }
    }
    return (
        <PageLayout>
            {isAuthenticated &&
                <Container maxWidth="lg" sx={{ paddingTop: 3 }} >
                    <PageHeader
                        icon="Settings"
                        title="Preferences"
                        titleDesc="Application preferences, configurations and settings." />

                    <List
                        sx={{ width: '100%' }}
                        subheader={<ListSubheader sx={{ backgroundColor: "background.default" }}>User Interface and Appearence</ListSubheader>}
                    >
                        <ListItem>
                            <ListItemIcon>
                                <ColorLens />
                            </ListItemIcon>
                            <ListItemText id="switch-list-label-darkmode"
                                primary="Dark mode"
                                secondary="Dark mode might reduce glare and make it comfortable on your eyes."
                            />
                            <Switch
                                data-testid="darkmode-switch"
                                edge="end"
                                onChange={toggleThemeMode}
                                checked={props.state.theme.mode === "dark" ? true : false}
                                inputProps={{
                                    'aria-labelledby': 'switch-list-label-darkmode',
                                }}
                            />
                        </ListItem>
                        <Divider />
                    </List>
                </Container>
            }
        </PageLayout>
    )
}


const mapStateToProps = (state: IRootState): SettingProps => ({
    state
});

const mapDispatchToProps = (dispatch: Dispatch): SettingDispatch => ({
    setThemeMode: (mode) => dispatch(themeActions.setThemeMode(mode))

})

export default connect(mapStateToProps, mapDispatchToProps)(SettingPage);