import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "../redux/UserReducer";
import dashboardReducer from "../redux/DashboardReducer";
import profileReducer from "../redux/ProfileReducer";
import qsearchbarReducer from "../redux/QSearchBarReducer";
import toasterReducer from "../redux/NotiReducer";
import mailFormReducer from "../redux/MailFormReducer";
import tutorialReducer from "../redux/TutorialReducer";
import { themeReducer } from "../redux/reducers";


export const rootReducer= combineReducers({
    user: userReducer,
    dashboard: dashboardReducer,
    profilePage: profileReducer,
    tutorialPage: tutorialReducer,
    qsearchbar: qsearchbarReducer,
    mailform: mailFormReducer,
    theme: themeReducer,
    toaster: toasterReducer,
});

const rootStore = configureStore({
    reducer: rootReducer,
    // middleware: () => new Tuple(additionalMiddleware, logger),
});

export default rootStore;
export type rootStoreType = ReturnType<typeof rootStore.getState>;