import React from "react";
import { PageLayout } from "../components/PageLayout";
import { Box, Container, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { PageHeader } from "../components/PageTitle";

export const TermsOfUsePage: React.FC = () => {
    const { isAuthenticated } = useAuth0();

    return (
        <PageLayout>
            <Container maxWidth="lg" sx={{ paddingTop: 3 }} component="main" >
                <PageHeader title="Iress Funds Flow Terms of Use" icon="Terms" />
                <Box sx={{ maxHeight: '70vh', overflow: "scroll", padding: 3 }}>
                    <Typography variant="h5" >Definitions</Typography>
                    <Typography variant="body1" >
                        <ol type="a">
                            <li>Funds Flow Data means any information, data, materials and output made available to the Customer in the course of Iress providing Iress Funds Flow.</li>
                            <li>Iress Funds Flow means the Iress Services as set out in the Supplemental Terms to this Service Order.</li>
                        </ol>
                    </Typography>

                    <Typography variant="h5" >Iress Funds Flow Services</Typography>
                    <Typography variant="body1" >
                        <ol type="a">
                            <li>Iress Funds Flow is a data technology offering provided by Iress.</li>
                            <li>The Customer may only use Iress Funds Flow and Funds Flow Data for its internal business purposes (‘Permitted Purpose’) and only subject to permissions, restrictions and requirements specified in the Agreement.</li>
                            <li>Subject to the Customer’s compliance with the Agreement, Iress grants the Customer a non-exclusive, terminable, non-transferable license to access and use Iress Funds Flow and Funds Flow Data for the Permitted Purpose during the Term of the relevant Service Order.</li>
                            <li>As between the parties, all Intellectual Property Rights in Iress Funds Flow, including but not limited to features, application, tools and the generation of such insights remain vested in Iress at all times.</li>
                        </ol>
                    </Typography>

                    <Typography variant="h5" >Restrictions</Typography>
                    <Typography variant="body1" >The Customer must not:</Typography>

                    <Typography variant="body1" >
                        <ol type="a">
                            <li>use Iress Funds Flow or Funds Flow Data for any purpose outside of the Permitted Purpose including for use as an input in its own products or services; or</li>
                            <li>copy, modify, create derivative works of, publicly display or perform, republish, store, transmit, or distribute Iress Funds Flow or Fund Flow Data without the prior written consent of Iress.</li>
                        </ol>
                    </Typography>

                    <Typography variant="h5" >Liability</Typography>
                    <Typography variant="body1" >
                        <ol type="a">
                            <li>The information and materials made available on Iress Funds Flow is provided on an “as is” and “as available” basis. To the maximum extent permitted by law, Iress does not make any warranties (express or implied), representations, endorsements or recommendations regarding the information and materials made available on Iress Funds Flow including, but not limited to:
                                <ol type="i">
                                    <li>the fitness for any purpose to which the Customer may use Iress Funds Flow, and</li>
                                    <li>the quality, accuracy, reliability and completeness of the information and materials provided through or in connection with Iress Funds Flow.</li>
                                </ol>
                            </li>
                            <li>Iress accepts no liability for any loss which may arise out of use of Iress Funds Flow or reliance on Funds Flow Data.</li>
                            <li>The Customer is solely responsible for any decisions it makes on the basis of Funds Flow Data.</li>
                            <li>The Customer agrees to indemnify and keep indemnified Iress, its affiliates, agents and third party information providers from and against any claims, actions, proceedings, liabilities, expenses, damages and costs, including all legal fees resulting from any breach by the Customer or External User, of these terms or unlawful or unauthorised use of Iress Funds Flow or Funds Flow Data.</li>
                        </ol>
                    </Typography>
                    <Typography variant="h5" >Marketing</Typography>
                    <Typography variant="body1" >
                        <ol type="a">
                            <li>Iress may request Customer participation by way of workshops and/or surveys for the purposes of collecting feedback about the Iress Services.</li>
                            <li>The Customer must not advertise or promote the name, service or description of Iress Funds Flow to any other party without the prior written consent of Iress.</li>
                        </ol>
                    </Typography>

                </Box >

            </Container >
            {isAuthenticated &&
                <Container maxWidth="lg" sx={{ justifyContent: "space-between", padding: 1 }} >
                    <Box sx={{ alignContent: "flex-end" }}>
                    {/* <FormControlLabel control={<Checkbox defaultChecked />} label="I have read, acknowledged and agreed the Funds Flow terms of use." /> */}
                    </Box>
                </Container>
            }
        </PageLayout >
    );
}
