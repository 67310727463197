// simple HTML5 video player component
const VideoPlayer = ({ videoKey }) => {
    const videoUrl = videoKey.startsWith("http") ? videoKey :`assets/videos/${videoKey}`;

    return (
        <video controls width="100%" autoPlay >
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    );
};

export default VideoPlayer;