import React from "react";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import MuiTheme from "../styles/MuiTheme";
import MuiNavBar from "../components/navigation/nav-bar-mui";
import NotiPad from "../components/NotiPad";
import { IRootState } from "../state/rootState";
import { HomePage } from "../section/HomePage";
import { NotFoundPage } from "../section/NotFoundPage";
import { LoadingPage } from "../section/LoadingPage";
import Dashboard from "../section/dashboard/Dashboard";
import DashboardDetail from "../section/dashboard/DashboardDetails";
import CallbackPage from "../section/callback/CallBackPage";
import ProfilePage from "../section/profile/Profiles";
import SettingPage from "../section/preferences/Preference";
import ProtectedRoutes from "../components/ProtectedRoutes";
import { TermsOfUsePage } from "../section/TermsOfUsePage";
import MailFormPage from "../section/MailForm";
import TutorialPage from "../section/tutorial/tutorial";

interface AppProps {
    state?: IRootState;
}

const mapStateToProps = (state: IRootState): AppProps => ({
    state
});

const App: React.FC<AppProps> = (props) => {

    const { isLoading } = useAuth0();


    if (isLoading) {
        return (<LoadingPage />);
    }

    return (
        <ThemeProvider theme={MuiTheme(props.state.theme.mode)}>
            <CssBaseline>
                <MuiNavBar />
                <Routes>
                    <Route path="/" element={<HomePage IsSessionTimeOut={false} />} />
                    <Route path="/callback" element={<CallbackPage />} />
                    <Route element={<ProtectedRoutes />} >
                        <Route path="/profile" element={<ProfilePage />} />
                        <Route path="/settings" element={<SettingPage />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/dashboard/detail" element={<DashboardDetail />} />
                        <Route path="/contactform" element={<MailFormPage />} />
                        <Route path="/tutorial" element={<TutorialPage />} />
                    </Route>
                    <Route path="/termsofuse" element={<TermsOfUsePage />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
                <NotiPad />
            </CssBaseline>
        </ThemeProvider>
    );
};

export default connect(mapStateToProps)(App);