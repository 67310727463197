import React from "react";
import { Container, Grid, Skeleton } from "@mui/material";

export const GenericLoader: React.FC = () => {
    const items = [1, 2, 3, 4, 5, 6];
    const generateSkeleton = items.map((item) => {
            return (
                <Grid item xs={12} md={4} key={item}>
                    <Skeleton variant="rectangular" height={150} />
                    <Skeleton />
                    <Skeleton width="60%" />
                </Grid>
            )
        })
    return (
        <Container maxWidth="lg" sx={{ paddingTop: 3 }} data-testid="generic-loader"  >
            <Grid container spacing={3} alignItems="center" justifyContent="center" >
                {generateSkeleton}
            </Grid>
        </Container>
    )
};
