
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IProfileState } from "../state/ProfileState";
import { sendPasswordResetEmail } from "../services/profile-request-service";

const InitialState: IProfileState = {
    Message: "",
    IsPassResetDone: false,
    IsLoading: false,
}

// this function will generate pending, fullfilled and rejected action types
export const fetchPasswordReset = createAsyncThunk(
    "profile/passwordReset",
    async ({ email }: any) => {
        return await sendPasswordResetEmail(email)
            .then((response) =>
                response.data ? response.data : "")
    });

const profileSlice = createSlice({
    name: "profile",
    initialState: InitialState,
    reducers: {
        clearMessage: (state) => {
            state.Message = "";
        }
    },
    extraReducers: builder => {
        // getDashboardList
        builder.addCase(fetchPasswordReset.pending, (state) => {
            state.IsLoading = true;
        })
        builder.addCase(fetchPasswordReset.fulfilled, (state, action) => {
            state.Message = action.payload;
            state.IsLoading = false;
            state.IsPassResetDone= true;
        })
        builder.addCase(fetchPasswordReset.rejected, (state, action) => {
            state.Message = action.error.message;
            state.IsLoading = false;
        })
    },
});

export default profileSlice.reducer;
export const profileActions = profileSlice.actions;