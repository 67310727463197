import * as React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, CircularProgress, Container, IconButton, Toolbar } from "@mui/material";
import logo from "./iress_logo.png";
import UserAccountMenu from './nav-components/user-menu-mui';
import MainMenu from './nav-components/main-menu-mui';
import { IMessageState, IRootState } from '../../state/rootState';
import { Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { NavButton } from './buttons/btn-nav';
import { useNavigate } from 'react-router-dom';
import { QSEARCHBAR_TOPIC_ID } from '../../shared/constants';
import rootStore from '../../state/rootStore';
import { loadQSearchBar } from '../../section/service/qsearchbar';
import { qSearchBarActions, fetchQSearchBar } from '../../redux/QSearchBarReducer';
import { toasterActions } from '../../redux/NotiReducer';

interface NavBarProps {
    state: IRootState;
}

interface NavBarDispatch {
    setQSearchBarLoaded: () => void;
    addToast: (message: IMessageState) => void;
}

type Props = NavBarProps & NavBarDispatch;

const MuiNavBar = (props: Props) => {
    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    const [open, setOpen] = React.useState(false);
    const [qbarLoaded, setQbarLoaded] = React.useState<boolean>(false);
    const dispatch = useDispatch<typeof rootStore.dispatch>();
    const navigate = useNavigate();


    const toggleMainMenu = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    const addNotification = (message: string) => {
        const Msg: IMessageState = {
            Type: "info",
            Message: message
        };
        props.addToast(Msg);
    }

    const addErrorNotification = (message: string) => {
        const Msg: IMessageState = {
            Type: "error",
            Message: message
        };
        props.addToast(Msg);
    }

    const checkLoadQSearchBar = async () => {
        if (props.state.qsearchbar.IsLoaded === false && !qbarLoaded && QSEARCHBAR_TOPIC_ID !== "") {
            setQbarLoaded(true);
            props.setQSearchBarLoaded();
            const accessToken = await getAccessTokenSilently();
            const data: any = await dispatch(fetchQSearchBar({ accessToken, topicId: QSEARCHBAR_TOPIC_ID }));

            if (data.payload.EmbedUrl) {
                const url = data.payload.EmbedUrl;
                const config = {
                    qsearchbarUri: url,
                    errorCallback: addErrorNotification,
                    // statusCallback: addNotification
                };

                document.getElementById("qSearchBarContainer").innerHTML = "";

                loadQSearchBar(config)
                    .then(() => addNotification('Q Search Bar Loaded' ))
                    .catch((err) => props.addToast({ Type: "error", Message: err }));
            }
        }
    }
    isAuthenticated && QSEARCHBAR_TOPIC_ID !== "" &&
        checkLoadQSearchBar();

    return (
        <AppBar position="fixed">
            <Container maxWidth="xl">
                <Toolbar disableGutters >
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'flex' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={toggleMainMenu(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    {/* this is for mobile */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <img
                            className="nav-bar-logo"
                            src={logo}
                            alt="iress logo"
                            style={{ height: '40px' }}
                        />
                    </Box>
                    {/* this is computer screen */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, marginRight: 3 }}>
                        <img
                            onClick={() => navigate("/")}
                            className="nav-bar-logo"
                            src={logo}
                            alt="iress logo"
                            style={{ height: '40px' }}
                        />
                    </Box>
                    {/* QSearchBar Container */}
                    {isAuthenticated &&
                        <Box sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'flex' },
                            marginRight: 3,
                            overflow: "visible",
                            maxHeight: "40px",
                            backgroundColor: "transparent"
                        }} >
                            {props.state.qsearchbar.IsLoading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: 'white',
                                        position: 'absolute',
                                        left: '50%',
                                        top: '30%',
                                    }}
                                />
                            )}
                            <div id="qSearchBarContainer" />
                        </Box>
                    }
                    {isAuthenticated ? (
                        <UserAccountMenu user={user} />
                    ) :
                        (
                            <Box sx={{ flexGrow: 0 }}>
                                <NavButton
                                    is_menu={false}
                                    label="Login"
                                    auth0_function="login"
                                    route="/dashboard"
                                />
                            </Box >
                        )}
                </Toolbar>
                <MainMenu toggleFunction={setOpen} openState={open} />
            </Container>
        </AppBar>
    );
}


const mapStateToProps = (state: IRootState): NavBarProps => ({
    state
});

const mapDispatchToProps = (dispatch: Dispatch): NavBarDispatch => ({
    setQSearchBarLoaded: () => dispatch(qSearchBarActions.setQSearchBarLoaded()),
    addToast: (message: IMessageState) => dispatch(toasterActions.addToast(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(MuiNavBar);
