import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import { PageLayout } from "../../components/PageLayout";
import { postRegUser } from "../../services/dashboard-request-service";
import { IRootState } from "../../state/rootState";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { GenericLoader } from "../../components/GenericLoader";
import { userActions } from "../../redux/UserReducer";
import { useNavigate } from "react-router-dom";

interface CallBackProps {
    state: IRootState;
}

interface CallBackDispatch {
    setAuth0User: (payload) => void;
}

type Props = CallBackProps & CallBackDispatch;

// main component render
export const CallbackPage: React.FC<Props> = (props) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const { user, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const regUser = async () => {
        if (!isLoaded) {
            setIsLoaded(true);
            props.setAuth0User(user);
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await postRegUser(accessToken);

            if (data) {
                // for other regular redirect use: navigate("/dashboard");
                // redirected form Auth0 and include the hash parameter which might have session timeout error on refresh.
                // window.location.href = "/#/dashboard";
                navigate("/dashboard");
            }

            if (error) {
                alert("Error: in user registration.");
            }
        }
    }

    regUser();

    return (
        <PageLayout>
            <GenericLoader />
        </PageLayout>
    );
};

const mapStateToProps = (state: IRootState): CallBackProps => ({
    state
});

const mapDispatchToProps = (dispatch: Dispatch): CallBackDispatch => ({
    setAuth0User: (payload) => dispatch(userActions.setUser(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(CallbackPage);