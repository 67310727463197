import { AxiosRequestConfig } from "axios";
import { ApiResponse } from "../models/api";
import { callExternalApi } from "./external-api-service";
import {
    REACT_APP_API_SERVER_URL,
    REACT_APP_API_SERVER_PROTOCOL,
    REACT_APP_SOURCE_APPLICATION,
} from "../shared/constants";

const idpDashboardServerUrl = REACT_APP_API_SERVER_URL;
const idpServerProtocol = REACT_APP_API_SERVER_PROTOCOL;
const sourceApplication = REACT_APP_SOURCE_APPLICATION;
// request the dashboard list. Requires Barr token
export const getDashboardList = async (accessToken: string): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
        url: `${idpServerProtocol}://${idpDashboardServerUrl}/api/quicksight/dashboards`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "content-type": "application/json",
            "source-application": sourceApplication ? sourceApplication : "unknown",
        },
    };
    const { data, error } = (await callExternalApi({ config })) as ApiResponse;
    
    return {
        data,
        error,
    };
};

// request the dashboard url. Requires Barr token and dashboard id
export const getDashboardUrl = async (accessToken: string, dashboardId: string): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
        url: `${idpServerProtocol}://${idpDashboardServerUrl}/api/quicksight/dashboards/${dashboardId}`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "content-type": "application/json",
            "source-application": sourceApplication ? sourceApplication : "unknown",
        },
    };

    const { data, error } = (await callExternalApi({ config })) as ApiResponse;

    return {
        data,
        error,
    };
};

// user registration
export const postRegUser = async (accessToken: string): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
        url: `${idpServerProtocol}://${idpDashboardServerUrl}/api/quicksight/register`,
        method: "POST",
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "content-type": "application/json",
            "source-application": sourceApplication ? sourceApplication : "unknown",
        },
    };

    const { data, error } = (await callExternalApi({ config })) as ApiResponse;

    return {
        data,
        error,
    };
};

