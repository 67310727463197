import { createTheme, PaletteMode } from "@mui/material";
import rootStore from "../state/rootStore";


const MuiTheme = (mode?: PaletteMode) => {
    const theme_mode = mode ? mode : 'dark';
    const setTheme = (mode: PaletteMode) => ({
        palette: {
            mode: theme_mode,
            ...(mode === 'light' ? {
                primary: {
                    main: "#361a3f",
                },
                secondary: {
                    main: "#f50057",
                },
                background: {
                    default: "#FAFAFA"
                }
            } : {
                primary: {
                    main: "#27bc9c",
                },
                secondary: {
                    main: "#f50057",
                },
                background: {
                    default: "#361a3f"
                }
            }),
        },
        typography: {
            // In Chinese and Japanese the characters are usually larger,
            // so a smaller fontsize may be appropriate.
            fontSize: 12,
        },
        components: {
            MuiListItemButton: {
                defaultProps: {
                    disableTouchRipple: true,
                },
            },
        }
    });
    return createTheme(setTheme(theme_mode));
}

export const MuiThemeConst = createTheme({
    palette: {
        mode: rootStore.getState().theme.mode,
        primary: {
            main: "#361a3f",
        },
        secondary: {
            main: "#f50057",
        },
    },
    components: {
        MuiListItemButton: {
            defaultProps: {
                disableTouchRipple: true,
            },
        },
    },
})

export default MuiTheme;

