import React from 'react';
import { Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { Alert, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemIcon, ListItemText, Skeleton } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { PageLayout } from '../../components/PageLayout';
import { profileActions } from '../../redux/ProfileReducer';
import rootStore from '../../state/rootStore';
import { OndemandVideo } from '@mui/icons-material';
import { ITutorialItem, ITutorialPage } from '../../state/tutorialState';
import { fetchTutorialListPublic, tutorialActions } from '../../redux/TutorialReducer';
import VideoPlayer from '../../components/VideoPlayer';
import { PageHeader } from '../../components/PageTitle';


interface TutorialProps {
    state: ITutorialPage;
}

interface TutorialDispatch {
    clearMessage: () => void;
}

type Props = TutorialProps & TutorialDispatch;

// main component render
export const TutorialPage: React.FC<Props> = (props) => {
    // if auth0 is not used, use user properties from redux store
    const { isAuthenticated } = useAuth0();
    const dispatch = useDispatch<typeof rootStore.dispatch>();
    const [loadq, setLoadQ] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const showTutorial = (tutorial: ITutorialItem) => {
        dispatch(tutorialActions.setActiveTutorial(tutorial));
        setOpen(true)
    };
    const closeTutorial = () => {
        setOpen(false)
        dispatch(tutorialActions.clearActiveTutorial());
    };

    const loadTutorial = async () => {
        if (loadq === false) {
            setLoadQ(true);
            // const accessToken = await getAccessTokenSilently();
            // await dispatch(fetchTutorialList({ accessToken }));
            dispatch(fetchTutorialListPublic());
        }
    }

    const showLoader = ['1', '2', '3', '4', '5'].map((obj) => {
        return (
            <>
                <Skeleton variant="rectangular" height={50} /><br />
            </>
        )
    })

    isAuthenticated && loadTutorial();

    const renderTutorialList = props.state.TutorialList.map((tutorial: ITutorialItem) => {
        return (
            <>
                <ListItem key={tutorial.key} >
                    <ListItemIcon onClick={() => showTutorial(tutorial)}>
                        <OndemandVideo />
                    </ListItemIcon>
                    <ListItemText
                        primary={tutorial.title}
                        secondary={tutorial.description} />
                    <Button onClick={() => showTutorial(tutorial)} > Watch</Button>

                </ListItem>
                <Divider />
            </>
        )
    });


    return (<PageLayout>
        {isAuthenticated &&
            <Container maxWidth="lg" sx={{ paddingTop: 3 }} >
                <PageHeader
                    icon="School"
                    title="Tutorials"
                    titleDesc="Learn more about IRESS Market Intelligence and Insight" />
                {props.state.ActiveTutorial &&
                    <Dialog
                        open={open}
                        onClose={closeTutorial}
                    >
                        <DialogTitle>{props.state.ActiveTutorial.title}</DialogTitle>
                        <DialogContent>
                            <VideoPlayer videoKey={props.state.ActiveTutorial.file_name} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeTutorial} autoFocus>
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
                {!props.state.IsLoading &&
                    <List >
                        {props.state.TutorialList.length > 0 ? renderTutorialList : 
                        <Alert variant='outlined' severity="info" >No tutorial available at the moment.</Alert>}
                    </List>
                }
                {props.state.IsLoading && showLoader}
            </Container>
        }
    </PageLayout>
    )
}

const mapStateToProps = (): TutorialProps => ({
    state: rootStore.getState().tutorialPage
});

const mapDispatchToProps = (dispatch: Dispatch): TutorialDispatch => ({
    clearMessage: () => dispatch(profileActions.clearMessage())
})

export default connect(mapStateToProps, mapDispatchToProps)(TutorialPage);