import { useAuth0 } from "@auth0/auth0-react";
import { Button, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import { BlurOn, Logout, Person, Settings } from "@mui/icons-material";

export const NavButton: React.FC<btnProps> = (props) => {

    // use Auth0 library to login with redirect
    const { loginWithRedirect, logout } = useAuth0();
    const navigate = useNavigate();

    const handleClick = async () => {

        switch (props.auth0_function) {
            case "signup": {
                await loginWithRedirect({
                    // redirect url after login process
                    appState: {
                        returnTo: props.route,
                    },
                    authorizationParams: {
                        prompt: "login",
                        screen_hint: "signup",
                    },
                });
                break;
            }
            case "logout": {
                logout({
                    logoutParams: {
                        returnTo: window.location.origin,
                    },
                });
                break;
            }
            case "login": {
                await loginWithRedirect({
                    appState: {
                        returnTo: "/callback",
                    },
                    authorizationParams: {
                        prompt: "login",
                    },
                });
                break;
            }

            default: {
                navigate(props.route ? props.route : "/check")
            }
        }

        if (props.onClose) {
            props.onClose();
        }

    };
    const setIcon = (label: string) => {
        switch (label.toUpperCase()) {
            case "PREFERENCES":
                return (
                    <Settings fontSize="small" />
                )
            case "ACCOUNT DETAIL":
                return (
                    <Person fontSize="small" />
                )
            case "LOGOUT":
                return (
                    <Logout fontSize="small" />
                )
            default:
                return (
                    <BlurOn fontSize="small" />
                )
        }
    };

    return props.is_menu ? (
        <MenuItem key={props.label.replace(/\s+/g, '-').toLowerCase()}
            onClick={handleClick}
            data-testid="test-menu-button" >
            <ListItemIcon>
                {setIcon(props.label)}
            </ListItemIcon>
            <ListItemText>{props.label}</ListItemText>
        </MenuItem>
    ) : (
        <Button key={props.label.replace(/\s+/g, '-').toLowerCase()}
            sx={{ my: 2, color: 'white', display: 'block' }}
            onClick={handleClick}
            data-testid="test-button" >
            {props.label}
        </Button>
    )
};

interface btnProps {
    is_menu: boolean;
    label: string;
    auth0_function?: any;
    route: string;
    onClose?: () => void;
}