import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getQSearchBar } from "../services/qsearchbar-request-service";
import { IQSearchBarState } from "../state/rootState";

const InitialState: IQSearchBarState = {
    IsLoaded: false,
    IsLoading: false,
    QSearchBarUri: ""
}


export const fetchQSearchBar = createAsyncThunk(
    "qsearchbar/getQSearchBar",
    async ({ accessToken, topicId }: any) => {
        return await getQSearchBar(accessToken, topicId)
            .then((response) =>
                response.data ? response.data : {})
    });

const qSearchBarSlice = createSlice({
    name: "qsearchbar",
    initialState: InitialState,
    reducers: {
        setQSearchBarLoaded: (state) => {
            state.IsLoaded = true;
        },
        setQSearchBarUri: (state, action) => {
            state.IsLoading = false;
            state.QSearchBarUri = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchQSearchBar.pending, (state, action) => {
            state.IsLoaded = true;
            state.IsLoading = true;
        });
        builder.addCase(fetchQSearchBar.fulfilled, (state, action) => {
            state.IsLoaded = true;
            state.IsLoading = false;
            state.QSearchBarUri = action.payload.EmbedUrl;
        });
        builder.addCase(fetchQSearchBar.rejected, (state, action) => {
            state.IsLoaded = true;
            state.IsLoading = false;
        });
    }
});

export default qSearchBarSlice.reducer;
export const qSearchBarActions = qSearchBarSlice.actions;