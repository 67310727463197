import { Alert, IconButton, Snackbar } from "@mui/material";
import React from "react";
import { IRootState } from "../state/rootState";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { toasterActions } from "../redux/NotiReducer";
import { Close } from "@mui/icons-material";


interface NotiProps {
    state: IRootState;
}

interface NotiDispatch {
    clearToasts: () => void;
    delToast: (index: number) => void;
}

type Props = NotiProps & NotiDispatch;

export const NotiPad: React.FC<Props> = (props) => {
    const actionCloseSnackbar = (index) => {
        return (
            <React.Fragment>
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => props.delToast(index)}
                >
                    <Close fontSize="small" />
                </IconButton>
            </React.Fragment>
        )
    };

    const activeTosters = props.state.toaster.map((toaster, index) => {
        return (
            <Snackbar
                key={index}
                sx={{ width: 500, paddingTop: 1 }}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={true}
                action={actionCloseSnackbar(index)}
                onClose={(e) => props.delToast(index)}
                autoHideDuration={3000}
                message={toaster.Message}
            >
                <Alert onClose={(e) => props.delToast(index)} severity={toaster.Type} sx={{ width: '100%', padding: 1 }}>
                    {toaster.Message}
                </Alert>
            </Snackbar>
        );
    });

    return (
        <div>
            {activeTosters}
        </div>
    );
};


const mapStateToProps = (state: IRootState): NotiProps => ({
    state
});

const mapDispatchToProps = (dispatch: Dispatch): NotiDispatch => ({
    clearToasts: () => dispatch(toasterActions.clearToasts()),
    delToast: (index: number) => dispatch(toasterActions.delToast(index))
})

export default connect(mapStateToProps, mapDispatchToProps)(NotiPad);