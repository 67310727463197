import { AppState, Auth0Provider } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import {
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_CLIENT_ID,
    REACT_APP_AUTH0_AUDIENCE,
    REACT_APP_AUTH0_CALLBACK_URL
} from "../shared/constants";

interface Auth0ProviderWithConfigProps {
    children: React.ReactNode;
}

export const Auth0ProviderWithHistory = ({
    children,
}: PropsWithChildren<Auth0ProviderWithConfigProps>): JSX.Element | null => {
    const navigate = useNavigate();

    const domain = REACT_APP_AUTH0_DOMAIN;
    const clientId = REACT_APP_AUTH0_CLIENT_ID;
    const audience = REACT_APP_AUTH0_AUDIENCE;
    const redirectUri = REACT_APP_AUTH0_CALLBACK_URL;

    const onRedirectCallback = (appState?: AppState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    if (!(domain && clientId && redirectUri && audience)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                audience: audience,
                redirect_uri: redirectUri,
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};
