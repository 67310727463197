import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';

interface IQSearchBarConfig {
    qsearchbarUri: string,
    container?: HTMLElement,
    errorCallback?: Function,
    statusCallback?: Function, // generic to handle all status callbacks
}

export const loadQSearchBar = async ({ qsearchbarUri, container, errorCallback, statusCallback }: IQSearchBarConfig) => {

    const embeddingContext = await createEmbeddingContext({
        onChange: (changeEvent, metadata) => {
            console.log('Context received a change', changeEvent, metadata);
        },
    });

    const frameOptions = {
        url: qsearchbarUri,
        container: container ? container : "#qSearchBarContainer",
        height: "50px",
        width: "500px",
        panelType: 'SEARCH_BAR',
        title: 'Funds Flow Powered by Amazon Q',
        allowTopicSelection: true,
        hideTopicName: true,
        allowFullscreen: true,
        onChange: (changeEvent, metadata) => {
            switch (changeEvent.eventName) {
                case 'FRAME_MOUNTED': {
                    // Do something when the experience frame is mounted
                    if (typeof statusCallback === "function") { statusCallback("Frame mounted"); }
                    break;
                }
                case 'FRAME_LOADED': {
                    // Do something when the experience frame is loaded
                    if (typeof statusCallback === "function") { statusCallback("Frame loaded"); }
                    break;
                }
            }
        },
    };

    const contentOptions = {
        onMessage: async (messageEvent: any, experienceMetadata: any) => {
            switch (messageEvent.eventName) {
                case 'Q_SEARCH_OPENED': {
                    // Do something when Q Search content expanded
                    if (typeof statusCallback === "function") { statusCallback(messageEvent.eventName); }
                    break;
                }
                case 'Q_SEARCH_CLOSED': {
                    // Do something when Q Search content collapsed
                    if (typeof statusCallback === "function") { statusCallback(messageEvent.eventName); }
                    break;
                }
                case 'Q_SEARCH_SIZE_CHANGED': {
                    // Do something when Q Search size changed
                    if (typeof statusCallback === "function") { statusCallback(messageEvent.eventName); }
                    break;
                }
                case 'CONTENT_LOADED': {
                    // Do something when the Q Search is loaded
                    if (typeof statusCallback === "function") { statusCallback("Q Search loaded successfully loaded.") }
                    break;
                }
                case 'ERROR_OCCURRED': {
                    // Do something when the Q Search fails loading
                    if (typeof errorCallback === "function") { errorCallback("An error occured while loading the topic.") }
                    break;
                }
            }
        }
    };

    return await embeddingContext.embedQSearchBar(frameOptions, contentOptions);
};