import config from '../config.json';

export const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN ? process.env.REACT_APP_AUTH0_DOMAIN : config.react_app.auth0_domain;
export const REACT_APP_AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID ? process.env.REACT_APP_AUTH0_CLIENT_ID : config.react_app.auth0_client_id;
export const REACT_APP_AUTH0_CALLBACK_URL = process.env.REACT_APP_AUTH0_CALLBACK_URL ? process.env.REACT_APP_AUTH0_CALLBACK_URL : config.react_app.auth0_callback_url;
export const REACT_APP_AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE ? process.env.REACT_APP_AUTH0_AUDIENCE : config.react_app.auth0_audience;
export const REACT_APP_API_SERVER_PROTOCOL = process.env.REACT_APP_API_SERVER_PROTOCOL ? process.env.REACT_APP_API_SERVER_PROTOCOL : "https";
export const REACT_APP_API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL ? process.env.REACT_APP_API_SERVER_URL : config.react_app.external_api_server_url;
export const REACT_APP_SOURCE_APPLICATION = process.env.REACT_APP_SOURCE_APPLICATION ? process.env.REACT_APP_SOURCE_APPLICATION : config.react_app.source_application;
export const QSEARCHBAR_TOPIC_ID = process.env.QSEARCHBAR_TOPIC_ID ? process.env.QSEARCHBAR_TOPIC_ID : config.react_app.qsearchbar_topic_id;

