import React from "react";


export interface iPageLayoutProps {
    children?: React.ReactNode;
}

export const PageLayout: React.FC<iPageLayoutProps> = (props) => {
    return (
        <div style={{ marginTop: 65, padding: 0 }}>{props.children}</div>
    );
};

export default PageLayout;