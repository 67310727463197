import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiResponse } from "../models/api";

export const callExternalApi = async (options: {
    config: AxiosRequestConfig;
}): Promise<ApiResponse> => {
    try {
        const response: AxiosResponse = await axios(options.config);
        const { data } = response;

        return {
            data,
            error: null,
        };
    } catch (error) {

        return {
            data: null,
            error: {
                message: (error as Error).message,
            },
        };
    }
};
