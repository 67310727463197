import { AxiosRequestConfig } from "axios";
import { ApiResponse } from "../models/api";
import { callExternalApi } from "./external-api-service";
import { 
    REACT_APP_AUTH0_CLIENT_ID,
    REACT_APP_AUTH0_DOMAIN 
} from "../shared/constants";

export const sendPasswordResetEmail = async (email:string): Promise<ApiResponse> => {
    // this will trigger password reset email to user.
    const config: AxiosRequestConfig = {
        url: `https://${REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        data: {
            client_id: REACT_APP_AUTH0_CLIENT_ID,
            email,
            connection: "Username-Password-Authentication"
        }
    };
    const { data, error } = (await callExternalApi({ config })) as ApiResponse;

    return {
        data,
        error,
    };
};
