import React, { useCallback, useEffect, useRef } from 'react';
import { IRootState } from '../../state/rootState';
import { Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { PageLayout } from '../../components/PageLayout';
import { loadDashboard } from '../service/dashboard';
import { dashboardActions, fetchDashboardUrl } from '../../redux/DashboardReducer';
import rootStore from '../../state/rootStore';
import { LinearProgress } from '@mui/material';
import { toasterActions } from '../../redux/NotiReducer';


interface DashboardDetailProps {
    state: IRootState;
}

interface DashboardDetailDispatch {
    setDashboardActiveCalled: () => void;
    setDashboardLoaded: () => void;
    setNoti: (payload: string) => void;
    setLoadingStatus: (status: boolean) => void;
}

type Props = DashboardDetailProps & DashboardDetailDispatch;

// main component render
export const DashboardDetail: React.FC<Props> = (props) => {
    const refDashboardContainer = useRef(null);
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch<typeof rootStore.dispatch>();

    const loadActiveDashboard = useCallback(async () => {

        if (!props.state.dashboard.DashboardLoaded) {
            props.setLoadingStatus(true);
            props.setDashboardLoaded();
            props.setNoti('Loading dashboard ......');
            try {
                if (props.state.dashboard.ActiveDashboard) {
                    const accessToken = await getAccessTokenSilently();
                    const dashboardId = props.state.dashboard.ActiveDashboard.DashboardId;
                    const data: any = await dispatch(fetchDashboardUrl({ accessToken, dashboardId }));

                    if (data.payload.EmbedUrl) {
                        const url = data.payload.EmbedUrl;

                        const config = {
                            dashboardUri: url,
                            container: refDashboardContainer.current as unknown as HTMLElement,
                            errorCallback: (err: any) => { props.setNoti('Error configuring dashboard ' + err); },
                            loadCallback: () => props.setLoadingStatus(false)
                        };


                        // clear any existing dashboard
                        if (refDashboardContainer.current)
                            refDashboardContainer.current.innerHTML = "";
                        // same codew as document.getElementById("dashboardContainer").innerHTML = "";

                        // dashboard call create new iframe in page
                        // remove any existing iframe form page first
                        // this is to avoid multiple iframe in page
                        const iFrames: HTMLIFrameElement[] = Array.from(document.getElementsByTagName('iframe'));
                        if (iFrames.length > 0) {
                            iFrames.forEach((iFrame, index) => {
                                // leave iframe for QSearchBar
                                index > 1 &&
                                    iFrame.parentNode?.removeChild(iFrame);
                            });
                        }

                        loadDashboard(config)
                            .then(() => console.log('Dashboard loading initiated'))
                            .catch((err) => {
                                props.setNoti('Error initiating dashboard load ' + err);
                                props.setLoadingStatus(false);
                            });
                    } else {
                        props.setNoti('Error loading dashboard: ' + data.message ? data.message : "");
                    }

                } else {
                    props.setNoti('No active dashboard selected');
                    props.setLoadingStatus(false);
                }
                props.setLoadingStatus(false);
            } catch (error) {
                props.setNoti(error);
                props.setLoadingStatus(false);
            }
        }
    }, [props, refDashboardContainer, dispatch, getAccessTokenSilently]);


    // let react know somthing do after render. Here load data after render
    useEffect(() => {
        loadActiveDashboard();
    }, [loadActiveDashboard, refDashboardContainer]);

    return (
        <PageLayout>
            {props.state.dashboard.IsLoading && <LinearProgress color='primary' sx={{ height: 7 }} />}
            <div id="dashboardContainer" ref={refDashboardContainer} data-testid="dashbord-container-frame" />
        </PageLayout>
    );
}

const mapStateToProps = (state: IRootState): DashboardDetailProps => ({
    state
});

const mapDispatchToProps = (dispatch: Dispatch): DashboardDetailDispatch => ({
    setDashboardActiveCalled: () => dispatch(dashboardActions.setDashboardActiveCalled()),
    setDashboardLoaded: () => dispatch(dashboardActions.setDashboardLoaded()),
    setNoti: (payload) => dispatch(toasterActions.addToast({ Type: "info", Message: payload })),
    setLoadingStatus: (payload) => dispatch(dashboardActions.setLoadingStatus(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDetail);