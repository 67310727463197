import { createSlice } from "@reduxjs/toolkit";
import { ITheme } from "../state/rootState";

const initialTheme:ITheme = {
    mode: "light"
}

const themeSlice = createSlice({
    name: "theme",
    initialState: initialTheme,
    reducers: {
        setThemeMode: (state, action) => {
            state.mode = action.payload;
        },
    }
});

export const themeReducer = themeSlice.reducer;
export const themeActions = themeSlice.actions;